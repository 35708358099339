@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .special-rounded {
    @apply rounded;
  }

  .special-rounded-big {
    @apply rounded-lg rounded-lg;
  }

  .default-solid-border {
    @apply border-solid border border-gray-400;
  }

  .blob-rounded {
    border-radius: 65% 30% 60% 25%;
  }
}

html, body {
  min-height: 100%;
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
body {
  margin: 0;
  padding: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-50;
  @apply text-gray-900;
}

.negative-container {
  @apply text-white;
}

h1 {
  @apply text-2xl;
  @apply tracking-wider;
}

h1,
h2 {
  @apply leading-none;
}

h1,
h2,
h3 {
  @apply font-semibold;
  font-family: Lexend, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.header-font, p {
  font-family: Lexend, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a,
a *,
button {
  transition: all .15s ease;
}
